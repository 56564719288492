import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { IonSpinner } from '@ionic/react';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from '@material-ui/core/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import './LoginForm.scss';
import {
  fetchBalance,
  loginSuccess,
  loginFailed,
  requestStart,
  requestEnd,
} from '../../store';
import { RootState } from '../../models/RootState';
import { IonLabel } from '@ionic/react';
import SVLS_API from '../../svls-api';
import { DomainConfig } from '../../models/DomainConfig';
import SocialMedia from '../SocialMedia/SocialMedia';
type StoreProps = {
  loginSuccess: Function;
  loginFailed: Function;
  requestStart: Function;
  requestEnd: Function;
  fetchBalance: Function;
  domainConfig: DomainConfig;
};

type LoginProps = StoreProps & {
  errorMsg: string;
  loading: boolean;
  loggedIn: boolean;
  modalCloseHandler: Function;
  redirectUrl?: string;
};

const LoginForm: React.FC<LoginProps> = (props) => {
  const {
    errorMsg,
    loading,
    loggedIn,
    loginSuccess,
    loginFailed,
    requestStart,
    requestEnd,
    fetchBalance,
    modalCloseHandler,
    redirectUrl,
    domainConfig,
  } = props;

  const [showPassword, setShowPassword] = useState(false);
  const [loginResponse, setLoginResponse] = useState(null);
  const [useAuthenticator, setUseAuthenticator] = useState<boolean>(false);
  const [showForgotPwdModal, setShowForgotPwdModal] = useState(false);
  const [loadLogin, setLoadLogin] = useState(false);
  const [demoLoading, setDemoLoading] = useState(false);

  let history = useHistory();
  const { search } = useLocation();
  const authToken = new URLSearchParams(search).get('authToken');
  useEffect(() => {
    if (authToken) {
      getSapToken(authToken);
      const username = authToken?.split('.')?.[1];
      let uname = JSON.parse(window?.atob(username)).sub;
      let uid = JSON.parse(window?.atob(username)).uid;
      sessionStorage.setItem('username', uname);
      sessionStorage.setItem('aid', uid);
      sessionStorage.setItem('jwt_token', authToken);
      let claim = authToken.split('.')[1];
      sessionStorage.setItem('aid', JSON.parse(window.atob(claim)).aid);
      loginSuccess(authToken);
      history.push('/home');
      fetchBalance();
    }
  }, [authToken]);

  const formik = useFormik({
    initialValues: { username: '', password: '', code: '' },
    validationSchema: Yup.object({
      username: Yup.string().required('Required'),
      password: Yup.string(),
      code: Yup.string(),
    }),
    onSubmit: async (values) => {
      requestStart();

      try {
        const loginRequest = {
          username: values.username.toLowerCase(),
          password: values.password.trim(),
        };
        const response = await SVLS_API.post('/account/v2/login', loginRequest);

        setLoginResponse(response.data);
        requestEnd();

        getSapToken(response.data);

        sessionStorage.setItem('username', values.username.toLowerCase());
        sessionStorage.setItem('jwt_token', response.data);

        localStorage.removeItem(
          `multiMarket_${values?.username?.toLowerCase()}`
        );

        loginSuccess(response.data);

        let claim = response.data.split('.')[1];
        let status = JSON.parse(window.atob(claim)).sts;

        sessionStorage.setItem('aid', JSON.parse(window.atob(claim)).aid);

        if (status === 2) {
          history.replace('/terms-and-conditions');
        } else if (status === 4) {
          history.replace('/reset-password');
        }

        if (redirectUrl) {
          console.log(redirectUrl);
          history.push(redirectUrl);
        }
      } catch (err) {
        loginFailed(err.response.data.message);
      } finally {
        requestEnd();
      }
    },
  });

  const getSapToken = async (token: string) => {
    try {
      const response = await SVLS_API.get(
        `/account/v2/accounts/${
          JSON.parse(window.atob(token.split('.')[1])).aid
        }/sap-token`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (response.status == 200) {
        sessionStorage.setItem('sap_token', response.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleDemoLogin = async () => {
    setDemoLoading(true);
    setLoadLogin(false);
    requestStart();
    try {
      const response = await SVLS_API.post('/account/v2/login-demo');
      setDemoLoading(false);
      if (response.status == 200) {
        setLoginResponse(response.data);
        requestEnd();

        let claim = response.data.split('.')[1];
        const username = JSON.parse(window.atob(claim)).sub;

        sessionStorage.setItem('username', username.toLowerCase());
        sessionStorage.setItem('jwt_token', response.data);
        localStorage.removeItem(`multiMarket_${username.toLowerCase()}`);

        loginSuccess(response.data);

        let status = JSON.parse(window.atob(claim)).sts;

        sessionStorage.setItem('aid', JSON.parse(window.atob(claim)).aid);

        if (redirectUrl) {
          history.push(redirectUrl);
        }
      }
    } catch (err) {
      setDemoLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    if (loggedIn) {
      fetchBalance();
    }
  }, [fetchBalance, loggedIn]);

  useEffect(() => {
    if (loggedIn) {
      history.push('/home');
      modalCloseHandler();
    }
  }, [loggedIn, modalCloseHandler]);

  const showPasswordClickHandler = () => {
    setShowPassword(!showPassword);
  };

  const onRedirectToHome = () => {
    history.push('/home');
  };

  return (
    <div className="login-form-page">
      <form
        onSubmit={formik.handleSubmit}
        className="login-form-ctn"
        autoComplete="off"
      >
        <div className="card-title">Sign in with Us</div>
        <div className='social-media-login'>
          <SocialMedia></SocialMedia>
        </div>
        <span className="usr-input">
          <IonLabel className="input-labell">
            Username <span className="red-text">*</span>
          </IonLabel>
          <TextField
            className="login-input-field user-name"
            type="text"
            name="username"
            placeholder="username"
            variant="outlined"
            error={
              formik.touched.username && formik.errors.username ? true : false
            }
            helperText={
              formik.touched.username && formik.errors.username
                ? formik.errors.username
                : null
            }
            {...formik.getFieldProps('username')}
          />
        </span>

        <div className="pwd-input">
          <IonLabel className="input-labell">
            Password <span className="red-text">*</span>
          </IonLabel>
          <FormControl
            className="login-input-field pwd-field"
            variant="outlined"
            error={
              formik.touched.password && formik.errors.password ? true : false
            }
          >
            <OutlinedInput
              id="standard-adornment-password"
              type={showPassword ? 'text' : 'password'}
              name="password"
              {...formik.getFieldProps('password')}
              placeholder="password"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={showPasswordClickHandler}
                    onMouseDown={showPasswordClickHandler}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            {formik.touched.password && formik.errors.password ? (
              <FormHelperText error id="my-helper-text">
                {formik.errors.password}
              </FormHelperText>
            ) : null}
          </FormControl>
        </div>

        {useAuthenticator ? (
          <span className="code-input">
            <IonLabel className="input-labell">Code</IonLabel>
            <TextField
              className="login-input-field user-name"
              type="text"
              name="username"
              variant="outlined"
              {...formik.getFieldProps('code')}
            />
          </span>
        ) : null}

        {errorMsg !== '' ? (
          <span className="login-err-msg">{errorMsg}</span>
        ) : null}

        <div className="login-demologin-btns">
          <Button
            className={
              domainConfig.demoUser
                ? 'login-form-btn'
                : 'login-form-btn-without-demologin'
            }
            color="primary"
            endIcon={
              loading && loadLogin ? <IonSpinner name="lines-small" /> : ''
            }
            type="submit"
            variant="contained"
          >
            Login
          </Button>
          {domainConfig.demoUser ? (
            <Button
              className="login-form-btn"
              color="primary"
              endIcon={demoLoading ? <IonSpinner name="lines-small" /> : ''}
              onClick={handleDemoLogin}
              variant="contained"
            >
              Demo Login
            </Button>
          ) : null}
        </div>

        {domainConfig.signup && (
          <div className="forgot-pwd">
            <NavLink to="/forgot-password">Forgot Password?</NavLink>
          </div>
        )}
      </form>
      <div className="bottom-link" onClick={onRedirectToHome}>
        Back to Home
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    loading: state.auth.loading,
    loggedIn: state.auth.loggedIn,
    errorMsg: state.auth.loginError,
    domainConfig: state.common.domainConfig,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    fetchBalance: () => dispatch(fetchBalance()),
    loginSuccess: (payload) => dispatch(loginSuccess(payload)),
    loginFailed: (err: string) => dispatch(loginFailed(err)),
    requestStart: () => dispatch(requestStart()),
    requestEnd: () => dispatch(requestEnd()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
