const SocialMediaLink = [
    {
        link : '',
        name:'facebook'
    },
    {
        link:'',
        name: 'instagram'
    },
    {
        link:'',
        name:'linkedin'
    },
    {
        link:'',
        name:'twitter'
    },
    {
        link:'',
        name:'telegram'
    }
];

export default {
    SocialMediaLink
}