import React, { useEffect, useState } from 'react';
import { IonButtons, IonImg } from '@ionic/react';
import Facebook from '../../assets/images/footer/Fb.svg';
import Instagram from '../../assets/images/footer/Instagram.svg';
import Linkedin from '../../assets/images/footer/Linkedin.svg';
import Twitter from '../../assets/images/footer/Twitter.svg';
import Telegram from '../../assets/images/footer/Telegram.svg';
import Youtube from '../../assets/images/footer/Youtube.svg';
import './SocialMedia.scss';
import SocialMediaLink from '../../socialmedialink/SocialMediaLink';
import { BRAND_DOMAIN, BRAND_NAME } from '../../constants/Branding';
import SVLS_API from '../../svls-api';
import { DomainConfig } from '../../models/DomainConfig';
import { RootState } from '../../models/RootState';
import { connect } from 'react-redux';

const socialmedialink = SocialMediaLink.SocialMediaLink;

const SocialMedia: React.FC<{ config: DomainConfig }> = ({ config }) => {
  const [contactData, setContactData] = useState<any[]>([]);
  const [FACEBOOK_LINK, setFACEBOOK_LINK] = useState<string>();
  const [INSTAGRAM_LINK, setINSTAGRAM_LINK] = useState<string>();
  const [LINKEDIN_LINK, setLINKEDIN_LINK] = useState<string>();
  const [TWITTER_LINK, setTWITTER_LINK] = useState<string>();
  const [TELEGRAM_NUMBER, setTELEGRAM_NUMBER] = useState<string>();

  const onLink = (linkname: string) => {
    let urlLink = '';
    if (FACEBOOK_LINK && linkname == 'facebook') {
      urlLink = FACEBOOK_LINK;
    }
    if (INSTAGRAM_LINK && linkname == 'instagram') {
      urlLink = INSTAGRAM_LINK;
    }
    if (LINKEDIN_LINK && linkname == 'linkedin') {
      urlLink = LINKEDIN_LINK;
    }
    if (TWITTER_LINK && linkname == 'twitter') {
      urlLink = TWITTER_LINK;
    }
    if (TELEGRAM_NUMBER && linkname == 'telegram') {
      urlLink = TELEGRAM_NUMBER;
    }
    if (!urlLink) {
      socialmedialink.forEach((element) => {
        if (element.name === linkname && element.link !== '') {
          urlLink = element.link;
          //window.open(urlLink, "_blank")
        }
      });
    }
    if (urlLink) {
      window.open(urlLink, '_blank');
    }
  };

  useEffect(() => {
    getContactDetails();
  }, []);

  const getContactDetails = async () => {
    try {
      const response = await SVLS_API.get(
        `/account/v2/books/${BRAND_DOMAIN}/contact-list`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.status === 200) {
        setContactData(response.data);
        response.data.map((item) => {
          if (item.contactType === 'FACEBOOK_LINK' && item.details !== '') {
            setFACEBOOK_LINK(item.details);
          }
          if (item.contactType === 'INSTAGRAM_LINK' && item.details !== '') {
            setINSTAGRAM_LINK(item.details);
          }
          if (item.contactType === 'LINKEDIN_LINK' && item.details !== '') {
            setLINKEDIN_LINK(item.details);
          }
          if (item.contactType === 'TWITTER_LINK' && item.details !== '') {
            setTWITTER_LINK(item.details);
          }
          if (item.contactType === 'TELEGRAM_NUMBER' && item.details !== '') {
            setTELEGRAM_NUMBER(item.details);
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <IonButtons
      className={
        config.payments
          ? 'new_social_media social-media-group'
          : 'social-media-group'
      }
    >
      <div className="sportbook-img fb-icon" onClick={() => onLink('facebook')}>
        <img src={Facebook} />
      </div>
      <div
        className="sportbook-img ig-icon"
        onClick={() => onLink('instagram')}
      >
        <img src={Instagram} />
      </div>
      <div className="sportbook-img" onClick={() => onLink('linkedin')}>
        <img src={Linkedin} />
      </div>
      <div
        className="sportbook-img twitter-icon"
        onClick={() => onLink('twitter')}
      >
        <img src={Twitter} />
      </div>
      <div className="sportbook-img " onClick={() => onLink('telegram')}>
        <img className="telegram-icon" src={Telegram} />
      </div>
      {BRAND_NAME.toLocaleLowerCase() === '365fairplay' ? (
        <div className="sportbook-img" onClick={() => onLink('youtube')}>
          <img className="telegram-icon" src={Youtube} />
        </div>
      ) : null}
    </IonButtons>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    config: state.common.domainConfig,
  };
};

export default connect(mapStateToProps, null)(SocialMedia);
